
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import AddButton from '../buttons/AddButton.vue'
import DeleteButton from '../buttons/DeleteButton.vue'
import DeleteModal from '../modals/DeleteModal.vue'

@Component({
  components: {
    AddButton,
    DeleteButton,
    DeleteModal
  }
})
export default class AdministrateVideosModal extends Vue {
  @Prop({ required: true }) show: boolean
  @Prop({ default: 0 }) readonly videoUploadSize!: number
  @Prop({ default: 0 }) readonly videoUploadPercentage!: number
  @Prop({ default: 0 }) readonly currentlyUploaded!: number
  @Prop({ default: '' }) readonly uploadVideoError!: string
  @Prop({ default: false }) readonly uploadCompleted!: boolean
  @Prop({ default: '' }) readonly photoSlug!: string

  deleteVideoLoading = false
  deleteModalOpen = false
  videoToDelete: { index: number, id: string, thumbnail: string, url: string } | null = null

  videos: { index: number, id: string, thumbnail: string, url: string }[] = []

  localShow = false

  fields = [
    { key: 'index', label: 'Nr.' },
    { key: 'thumbnail', label: this.$gettext('Thumbnail') },
    { key: 'url', label: this.$gettext('Video URL') },
    { key: 'actions', label: this.$gettext('Actions') }
  ]

  @Watch('show')
  onShowChanged(newVal: boolean) {
    this.localShow = newVal
  }

  get uploadVideoErrorState() {
    return this.uploadVideoError ? false : null
  }

  get actionsDisabled (): boolean {
    return this.videoUploadPercentage > 0 || this.deleteVideoLoading
  }

  @Watch('uploadCompleted')
  async onUploadCompletedChange(newValue: boolean) {
    if (newValue) {
      await this.fetchVideos()
      const url = this.videos[this.videos.length - 1].url
      this.selectVideoForPhoto(url)
    }
  }

  async fetchVideos(): Promise<void> {
    await axios.get(API_URLS.PHOTOS.USERS_STREAMIO_VIDEOS).then((response) => {
      this.videos = response.data
      this.videos.forEach((video, index) => {
        video.index = index
      })
    })
  }

  onHide() {
    this.$emit('update:show', false)
  }

  closeModal() {
    this.localShow = false
    this.$emit('update:show', false)
  }

  async deleteVideo(): Promise<void> {
    this.deleteVideoLoading = true
    const data = {
      data: {
        video_id: this.videoToDelete.id,
        url: this.videoToDelete.url,
        photo_slug: this.photoSlug
      }
    }
    await axios.delete(API_URLS.PHOTOS.DELETE_STREAMIO_VIDEO, data).then((response) => {
      this.$emit("video-deleted", response.data)
      this.deleteVideoLoading = false
      this.videoToDelete = null
    })
  }

  selectVideoForPhoto(url) {
    this.$emit('video-selected', url)
  }

  handleVideoUpload (event: Event): void {
    this.$emit("start-video-upload", event)
  }

  openDeleteModal (video: { index: number, id: string, thumbnail: string, url: string }): void {
    this.deleteModalOpen = true
    this.videoToDelete = video
  }
}
