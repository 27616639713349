
import { Component, Prop, Ref } from 'vue-property-decorator'
import axios from 'axios'
import { IPhoto } from '@/types/photos'
import FileItem from "@/components/file_uploader/FileItem.vue"
import draggable from 'vuedraggable'
import { API_URLS } from '@/utils/helpers'
import ImageCropper from '@/components/file_uploader/ImageCropper.vue'
import { isArray } from 'lodash'
import InfoBox from './InfoBox.vue'
import { CircleStencil, RectangleStencil } from "vue-advanced-cropper"
import ToastMixin from '@/mixins/ToastMixin'
@Component({
  components: {
    FileItem,
    draggable,
    ImageCropper,
    InfoBox
  }
})
export default class FileUploader extends ToastMixin {
  @Ref('fileInput') fileInput: HTMLInputElement
  @Prop({ required: false }) images: IPhoto[]
  @Prop({ required: false }) uploadUrl: string
  @Prop({ default: false }) multiple
  @Prop({ default: false }) isFile
  @Prop({ default: false }) acceptFiles
  @Prop({ default: false }) isLogo
  @Prop({ default: false }) useCropper
  @Prop({ default: '16:9' }) cropperFormat: string
  @Prop({ default: false }) showFileInfoBox
  @Prop({ required: false }) albumSlug: string
  @Prop({ default: false }) stencilCircle: boolean
  @Prop() uploadLimit: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stencil: any = null
  cropImage = null
  imageToRecrop = null
  showCropper = false

  get accept (): string {
    if (this.acceptFiles) {
      return '.pdf, application/pdf, image/*'
    }
    if (this.isFile) {
      return '.pdf, application/pdf'
    } else {
      return 'image/*'
    }
  }

  mounted (): void {
    if (this.stencilCircle) {
      this.stencil = CircleStencil
    } else {
      this.stencil = RectangleStencil
    }
  }

  get isMultiple (): boolean {
    if (this.useCropper) {
      return false
    } else {
      return this.multiple
    }
  }

  get addImgBtnText (): string {
    if (this.isFile) {
      return this.$gettext('Upload file')
    } else {
      return this.$gettext('Upload image')
    }
  }

  openFileInput (): void {
    if (this.images.length >= this.uploadLimit) {
      const title = this.$gettext("Limit reached")
      const message = this.$gettext("You have reached the maximum number of images.")
      this.makeToast("danger", title, message)
    } else {
      this.$emit("file-input-clicked")
      const inputEl = this.$refs.fileInput as HTMLInputElement
      inputEl.click()
    }
  }

  onRecropImage (image: IPhoto, openExplorer: boolean): void {
    this.imageToRecrop = image
    this.showCropper = true
    this.cropImage = image
    if (openExplorer) {
      this.openFileInput()
    }
  }

  onCroppingAborted (): void {
    this.showCropper = false
  }

  onImageCropped (fileList): void {
    this.uploadFileList(fileList)
    this.showCropper = false
  }

  onFileDeleted (image): void {
    this.$emit("file-deleted", image)
    this.onOrderChanged(image)
  }

  onVideoDeleted (photos: IPhoto[]): void {
    this.$emit("files-updated", photos)
  }

  onAlbumInfoFetched (albumInfo): void {
    this.$emit("album-info-fetched", albumInfo)
  }

  onFileInputChange (event: Event): void {
    this.cropImage = null
    const input = event.target as HTMLInputElement
    const file = input.files && input.files[0]
    if (file) {
      if (this.useCropper) {
        if (this.uploadUrl) {
          this.showCropper = true
          this.loadImageIntoCropper(file)
        } else {
          this.$emit("file-uploaded", [])
        }
      } else {
        const fileList = isArray(file) ? file : [file]
        this.uploadFileList(fileList)
      }
    }
  }

  onOrderChanged (deletedImage = null): void {
    let images = this.images
    // reset order, if img is deleted
    if (deletedImage) {
      images = images.filter(i => i.slug !== deletedImage.slug)
    }
    axios.post(API_URLS.PHOTOS.CHANGE_ORDER, images).then(response => {
      this.$emit("files-updated", response.data.updated_photos)
    })
  }

  loadImageIntoCropper (file: File): void {
    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.cropImage = e.target?.result as string
    }
    reader.readAsDataURL(file)
  }

  uploadFileList (fileList): void {
    this.$wait.start('upload image')
    const selectedFiles: File[] = fileList
    const url = this.uploadUrl
    if (!url) {
      // instance doesnt exist, so trigger create function with selected images
      this.$emit("file-uploaded", selectedFiles)
      return
    }
    const formData = new FormData()
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i])
    }
    if (this.imageToRecrop) {
      formData.append('update_photo_slug', this.imageToRecrop.slug)
      this.imageToRecrop = null
    }
    axios.post(url, formData).then(response => {
      this.$emit("files-updated", response.data)
      this.$wait.end('upload image')
    })
  }

  showSuccessMsg () {
    const title = this.$gettext("Success")
    const message = this.$gettext("Successfully updated")
    this.makeToast("success", title, message)
  }
}
