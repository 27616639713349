import { render, staticRenderFns } from "./FileItem.vue?vue&type=template&id=39601dda&scoped=true&"
import script from "./FileItem.vue?vue&type=script&lang=ts&"
export * from "./FileItem.vue?vue&type=script&lang=ts&"
import style0 from "./FileItem.vue?vue&type=style&index=0&id=39601dda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39601dda",
  null
  
)

export default component.exports