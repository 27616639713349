

import { Mixins, Component, Prop, Ref } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import { IProjectCategory } from '@/types/projects'
import { ISubCategory } from '@/types/foerderApp'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { BDropdown } from 'bootstrap-vue'
import ToastMixin from '@/mixins/ToastMixin'

@Component({
  mixins: [UserMixin]
})
export default class AddSubCategory extends Mixins(UserMixin, ToastMixin) {
  @Ref('dropdown') readonly dropdown!: BDropdown | null
  @Prop() projectCategory: IProjectCategory
  @Prop() instance: { type: string, slug: string }
  @Prop({ default: () => [] }) differentProjectCategoryIds: number[]
  subCategories: ISubCategory[] = []
  availableSubCategories: ISubCategory[] = []
  search = ""
  categoryVisible = false

  get chooseTagsText (): string {
    return this.$gettextInterpolate(this.$gettext('Choose tags in category: %{projectCategoryTitle}'), { projectCategoryTitle: this.projectCategory.title })
  }

  get filteredSubCategories (): ISubCategory[] {
    return this.availableSubCategories.filter(option => option.title.toLowerCase().includes(this.search.toLowerCase()))
  }

  get canAddTags (): boolean {
    let canAdd = false
    const value = this.differentProjectCategoryIds.includes(this.projectCategory.id)
    if (value) {
      canAdd = true
    } else if (this.differentProjectCategoryIds.length < 3) {
      canAdd = true
    }
    return canAdd
  }

  filterAvailableProjectCategories(): void {
    if (this.instance.type === 'project' && this.projectCategory.available_for_projects) {
      this.categoryVisible = true
    } else if (this.instance.type === 'organization' && this.projectCategory.available_for_organizations) {
      this.categoryVisible = true
    } else if (this.instance.type === 'funding_scope' && this.projectCategory.available_for_funding_scopes) {
      this.categoryVisible = true
    }
  }

  mounted (): void {
    this.filterAvailableProjectCategories()
    this.getAvailableSubCategories()
  }

  async getAvailableSubCategories (): Promise<void> {
    await axios.get(API_URLS.SUB_CATEGORIES.LIST_FOR_PROJECT_CATEGORY(this.projectCategory.slug))
      .then(response => {
        if (this.instance.type === 'project') {
          this.availableSubCategories = response.data.results.filter(subCategory => !subCategory.projects.includes(this.instance.slug))
          this.subCategories = response.data.results.filter(subCategory => subCategory.projects.includes(this.instance.slug))
        } else if (this.instance.type === 'organization') {
          this.availableSubCategories = response.data.results.filter(subCategory => !subCategory.organizations.includes(this.instance.slug))
          this.subCategories = response.data.results.filter(subCategory => subCategory.organizations.includes(this.instance.slug))
        } else if (this.instance.type === 'funding_scope') {
          this.availableSubCategories = response.data.results.filter(subCategory => !subCategory.funding_scopes.includes(this.instance.slug))
          this.subCategories = response.data.results.filter(subCategory => subCategory.funding_scopes.includes(this.instance.slug))
        }
        this.$emit("fetched-sub-categories", this.subCategories)
      })
  }

  async addTag (title: string): Promise<void> {
    if (!this.canAddTags) {
      this.makeToast('danger', this.$gettext('Error'), this.$gettext('You can only add up to 3 tags from different categories'))
      return
    }
    const data = {
      title,
      project_category: this.projectCategory.slug,
      instance_type: this.instance.type,
      instance_slug: this.instance.slug
    }
    await axios.post(API_URLS.SUB_CATEGORIES.ADD, data)
      .then(response => {
        this.search = ''
        this.subCategories.push(response.data)
        this.$emit("sub-category-added", this.projectCategory.id)
        this.availableSubCategories = this.availableSubCategories.filter(option => option.slug !== response.data.slug)
      })
  }

  removeTag (subCategory: ISubCategory): void {
    this.subCategories = this.subCategories.filter(tag => tag.slug !== subCategory.slug)
    this.availableSubCategories.push(subCategory)
    const data = {
      data: {
        instance_type: this.instance.type,
        instance_slug: this.instance.slug
      }
    }
    axios.delete(API_URLS.SUB_CATEGORIES.REMOVE(subCategory.slug), data)
    if (this.subCategories.length === 0) {
      this.$emit("sub-category-removed", this.projectCategory.id)
    }
  }
}
