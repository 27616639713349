
import { Component, Prop, Vue } from 'vue-property-decorator'

interface IBreadcrumb {
  title: string
  url: string
  routerUrl?: boolean
}

@Component({
  name: 'breadcrumb-header'
})
export default class BreadcrumbHeader extends Vue {
  @Prop({ default: () => [] }) breadcrumbs!: IBreadcrumb[]
  @Prop({ default: false }) isSiteAdmin!: boolean

  get homeHref (): string {
    return this.isSiteAdmin ? '/site-admin' : '/account'
  }
}
