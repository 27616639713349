
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ICustomTab, IExploreProjectDetail } from "@/types/projects"
import { IProjectDetailSettings } from '@/types/cms'
import { IMilestone } from '@/types/finances'
import ProjectCustomTab from '../ProjectCustomTab.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import ImageSlider from '@/components/explore/ImageSlider.vue'
import { IPhoto } from '@/types/photos'
import LoginModal from '@/components/modals/LoginModal.vue'
import FavouriteAddedModal from '@/components/modals/FavouriteAddedModal.vue'
import HdsTab from './HdsTab.vue'
import ReadMoreBtn from "@/components/explore/hds/ReadMoreBtn.vue"
import MobileMixin from '@/mixins/MobileMixin'

@Component({
  components: {
    ReadMoreBtn,
    ProjectCustomTab,
    ImageSlider,
    LoginModal,
    FavouriteAddedModal,
    HdsTab
  }
})
export default class ProjectOverviewFoerderapp extends Mixins(ToastMixin, UserMixin, MobileMixin) {
  @Prop() project!: IExploreProjectDetail
  @Prop({ default: null }) projectDetailSettings: IProjectDetailSettings
  @Prop() orgaColor: string

  loginModalOpen = false
  favouriteAddedModalOpen = false
  favouriteMouseover = false
  tabKeys = {
    Append: 1,
    ProjectPromoterDescriptionPre: 100,
    ProjectPromoterDescriptionPost: 101,
    ProjectPromoterGalleryPost: 102,
    ProjectPromoterProjectsPost: 103,
    ProjectPromoterDonationUsagePost: 104,
    ProjectPromoterVotingsPost: 105,
    ProjectPromoterStatisticsPost: 106,
    ProjectPromoterSupportPost: 107,
    ProjectDescriptionPre: 200,
    ProjectDescriptionPost: 201,
    ProjectFundingNeedsPost: 202,
    ProjectGalleryPost: 203,
    ProjectOrganizationPost: 204,
    ProjectStatisticsPost: 205,
    ProjectVotingRankPost: 206,
    ProjectSupportPost: 207
  }

  activeTabKey = null

  get logo (): string {
    const fundingScopeLogo = this.project.funding_scope?.logos && this.project.funding_scope.logos.length > 0 ? this.project.funding_scope.logos[0].src : null
    const organizationLogo = this.project.organization?.logo
    if (this.activeTabKey === 'key_organization') {
      return organizationLogo
    } else {
      return fundingScopeLogo || organizationLogo
    }
  }

  get currentImages (): IPhoto[] {
    if (!this.activeTabKey || this.activeTabKey === 'key_project') {
      return this.project.funding_scope.photos
    } else if (this.activeTabKey === 'key_organization') {
      return this.project.organization.photos
    } else {
      return this.project.photos
    }
  }

  get isFavourite (): boolean {
    if (this.userProfile?.favorite_projects) {
      return this.userProfile.favorite_projects.filter(fav => fav.slug === this.project.slug).length > 0
    }
    return false
  }

  get favouriteIconClass (): string {
    if (this.isFavourite) {
      return 'fad'
    } else if (this.favouriteMouseover) {
      return 'fas'
    }
    return 'far'
  }

  getTrimmedHtmlBefore (htmlText: string, maxlength: number): string {
    const div = document.createElement('div')
    div.innerHTML = htmlText

    let truncated = ''
    let length = 0

    function traverseNodes(node: Node) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (length + node.textContent.length > maxlength) {
          truncated += node.textContent.substring(0, maxlength - length)
          length = maxlength
        } else {
          truncated += node.textContent
          length += node.textContent.length
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        truncated += `<${(node as Element).tagName.toLowerCase()}>`

        Array.from(node.childNodes).forEach(childNode => {
          if (length < maxlength) {
            traverseNodes(childNode)
          }
        })

        truncated += `</${(node as Element).tagName.toLowerCase()}>`
      }
    }

    Array.from(div.childNodes).forEach(childNode => {
      if (length < maxlength) {
        traverseNodes(childNode)
      }
    })

    return truncated
  }

  handleTabHovered (event: MouseEvent, hovered: boolean, tabName: string): void {
    const target = event.currentTarget as HTMLElement
    if (!this.isActiveTab(tabName)) {
      if (hovered) {
        target.style.backgroundColor = this.orgaColor
      } else {
        target.style.backgroundColor = 'white'
      }
    }
  }

  getTabBackgroundColor (tabName) {
    if (this.isActiveTab(tabName)) {
      return {
        "background-color": this.orgaColor + " !important"
      }
    }
  }

  isActiveTab (tabName: string): boolean {
    return tabName === this.activeTabKey
  }

  getActiveTabClass (tabName: string): string {
    if (this.isActiveTab(tabName)) {
      return "hds-tab-btn-active"
    }
  }

  customTabs (tabKey: number): ICustomTab[] {
    return this.project.custom_tabs[tabKey]
  }

  handleTabActivated (tabKey: string): void {
    this.activeTabKey = tabKey
    this.$emit("tab-changed", tabKey)
  }

  async setFavourite () {
    if (this.userProfile.is_anonymous) {
      this.loginModalOpen = true
      return true
    }
    if (!this.isFavourite) {
      await axios.post(API_URLS.EXPLORE.PROJECTS.FAVOURITE(this.project.slug)).catch(() => {
        this.makeToast('danger', 'Error', 'Failed to add favorite')
      })
    } else {
      await axios.post(API_URLS.EXPLORE.PROJECTS.REMOVE_FAVOURITE(this.project.slug)).catch(() => {
        this.makeToast('danger', 'Error', 'Failed to remove favorite')
      })
    }

    this.fetchFavoriteProjects()
  }

  onLoginSuccess (): void {
    if (this.loginModalOpen) {
      this.loginModalOpen = false
      this.favouriteAddedModalOpen = true
    }
  }

  progressText (goal: IMilestone): string {
    if (this.projectDetailSettings.currencyDisplay.useCurrency) {
      return this.$gettextInterpolate(
        this.$gettext('%{ currentBalance } of %{ totalBalance }'),
        {
          currentBalance: goal.balance.in_currency_display,
          totalBalance: goal.goal.in_currency_display
        }
      )
    } else {
      return this.$gettextInterpolate(
        this.$gettext('%{ currentBalance } of %{ totalBalance } %{ coinName }'),
        {
          currentBalance: goal.balance.in_coins,
          totalBalance: goal.goal.in_coins,
          coinName: this.projectDetailSettings.currencyDisplay.coinNamePlural
        }
      )
    }
  }

  beforeDestroy () {
    document.querySelectorAll<HTMLElement>('.navbar-brand').forEach(brand => {
      brand.classList.remove("hide")
    })
  }

  mounted () {
    this.activeTabKey = this.project.custom_tabs[200] ? this.customTabs(200)[0].title : "key_project"
    const navbarBrand = document.querySelectorAll('.navbar-brand')
    if (navbarBrand.length > 0) {
      document.querySelectorAll<HTMLElement>('.navbar-brand').forEach(brand => {
        brand.classList.add("hide")
      })
    }
  }
}
